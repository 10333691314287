const INTERVAL = 3000;
const STAGGER = 150;
const CLASS_ACTIVE = 'is-active';
const CLASS_ANIMATE_OUT = 'animate-out';
const containers = Array.from(document.querySelectorAll('.image-rotation'));

containers.forEach(init);

function init(container) {
  const blocks = Array.from(
    container.querySelectorAll('.image-rotation__block')
  );
  const blockTogglers = blocks.map(getItemToggler);
  let interval;

  const startInterval = () => {
    return setInterval(() => {
      blockTogglers.forEach((toggler, idx) => {
        setTimeout(toggler, idx * STAGGER);
      });
    }, INTERVAL);
  };

  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      clearInterval(interval);
    } else {
      clearInterval(interval);
      interval = startInterval();
    }
  });

  interval = startInterval();
}

function getItemToggler(block) {
  const items = Array.from(block.querySelectorAll('.image-rotation__item'));
  let currentIdx = 0;

  items.forEach((item) => {
    item.addEventListener('transitionend', () => {
      item.classList.remove(CLASS_ANIMATE_OUT);
    });
  });

  return () => {
    const nextIdx = currentIdx >= items.length - 1 ? 0 : currentIdx + 1;

    items[currentIdx].classList.add(CLASS_ANIMATE_OUT);
    items[currentIdx].classList.remove(CLASS_ACTIVE);
    items[nextIdx].classList.add(CLASS_ACTIVE);
    currentIdx = nextIdx;
  };
}