document.addEventListener('DOMContentLoaded', function() {
    const navToggle = document.querySelector('.site-nav__toggle');
    const navMenu = document.querySelector('.site-nav__menu');
    const toggleClosed = document.querySelector('.site-nav__toggle-closed');
    const toggleOpen = document.querySelector('.site-nav__toggle-open');
    const menuItems = document.querySelectorAll('.site-nav__item');

    if (navToggle && navMenu) {
        navToggle.addEventListener('click', function() {
            const expanded = navToggle.getAttribute('aria-expanded') === 'true' || false;
            navToggle.setAttribute('aria-expanded', !expanded);
            navMenu.classList.toggle('site-nav__menu--open');

            if (expanded) {
                toggleClosed.style.display = 'inline';
                toggleOpen.style.display = 'none';
            } else {
                toggleClosed.style.display = 'none';
                toggleOpen.style.display = 'inline';
            }
        });
    }

    menuItems.forEach(menuItem => {
        const subMenu = menuItem.querySelector('.sub-menu');
        if (subMenu) {
            menuItem.querySelector('a').addEventListener('click', function(event) {
                const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                if (windowWidth <= 768) {
                    event.preventDefault();
                    menuItem.classList.toggle('active');
                }
            });

            subMenu.querySelectorAll('a').forEach(subMenuItem => {
                subMenuItem.addEventListener('click', function(event) {
                    event.stopPropagation();
                });
            });
        }
    });
});