document.addEventListener('DOMContentLoaded', function () {
    function toggleContent(clickedArrow) {
      var memberContent = clickedArrow.closest('.member__content');
      var toggleContainer = memberContent.querySelector('.member-text');
      var isHidden = toggleContainer.style.display === 'block';
  
      if (isHidden) {
        toggleContainer.style.display = 'none';
        memberContent.classList.remove('bg-lightgreen', 'is-active');
        clickedArrow.style.display = 'none';
        memberContent.querySelector('#arrow-down').style.display = 'inline-block';
      } else {
        toggleContainer.style.display = 'block';
        memberContent.classList.add('bg-lightgreen', 'is-active');
        clickedArrow.style.display = 'none';
        memberContent.querySelector('#arrow-up').style.display = 'inline-block';
      }
    }
  
    var arrowElements = document.querySelectorAll('.arrow');
    arrowElements.forEach(function (arrow) {
      arrow.addEventListener('click', function () {
        toggleContent(arrow);
      });
    });
  });