document.addEventListener("DOMContentLoaded", function() {
  let slideIdx = 0;
  showSlidesIfReady();

  function showSlidesIfReady() {
      let slides = document.getElementsByClassName("auto-slider");
      if (slides.length > 0) {
          showSlides();
      } else {
          setTimeout(showSlidesIfReady, 100); 
      }
  }

  function showSlides() {
      let i;
      let slides = document.getElementsByClassName("auto-slider");
      for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";  
      }
      slideIdx++;
      if (slideIdx > slides.length) {slideIdx = 1}    
      slides[slideIdx-1].style.display = "block";  
      setTimeout(showSlides, 2000); 
  }
});
