document.addEventListener('DOMContentLoaded', function () {
    var accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function (accordion) {
      accordion.addEventListener('click', function () {
        var volunteerContent = accordion.closest('.volunteer__content');
        var container = volunteerContent.closest('.container-100'); 
        var volunteerText = volunteerContent.querySelector('.volunteer__text');
        var moreSpan = volunteerContent.querySelector('#more');
        var lessSpan = volunteerContent.querySelector('#less');

        if (accordion.id === 'more') {
          volunteerText.classList.add('is-full');
          moreSpan.style.display = 'none';
          lessSpan.style.display = 'inline';
          container.classList.add('bg-pink'); 
        } else if (accordion.id === 'less') {
          volunteerText.classList.remove('is-full');
          moreSpan.style.display = 'inline';
          lessSpan.style.display = 'none';
          container.classList.remove('bg-pink');
        }
      });
    });
  });