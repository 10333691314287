document.addEventListener('DOMContentLoaded', function() {
    var leftNoDivs = document.querySelectorAll('.left-no');
    leftNoDivs.forEach(function(leftNoDiv) {
        var text = leftNoDiv.textContent.trim();
        var words = text.split(' ');
        var firstWord = words.shift();
        leftNoDiv.innerHTML = '<span class="first-word">' + firstWord + '</span> ' + words.join(' ');
    });

    var rightNoDivs = document.querySelectorAll('.right-no');
    rightNoDivs.forEach(function(rightNoDiv) {
        var text = rightNoDiv.textContent.trim();
        var words = text.split(' ');
        var firstWord = words.shift();
        rightNoDiv.innerHTML = '<span class="first-word">' + firstWord + '</span> ' + words.join(' ');
    });

    var circles = document.querySelectorAll('.circle');
    circles.forEach(function(circle) {
        var text = circle.textContent.trim();
        var words = text.split(' ');
        var firstWord = words.shift();
        circle.innerHTML = '<span class="first-word-circles">' + firstWord + '</span> ' + words.join(' ');
    });

});