document.addEventListener('DOMContentLoaded', function () {
    let slideIndex = 1;
    showSlidesIfReady(slideIndex);

    function showSlidesIfReady(n) {
        let slides = document.getElementsByClassName("slide");
        if (slides.length > 0) {
            showSlides(n);
            let prevButton = document.querySelector('.prev');
            let nextButton = document.querySelector('.next');
            if (prevButton) {
                prevButton.addEventListener('click', function () {
                    plusSlides(-1);
                });
            }
            if (nextButton) {
                nextButton.addEventListener('click', function () {
                    plusSlides(1);
                });
            }
        } else {
            setTimeout(function () {
                showSlidesIfReady(n);
            }, 100); // Retry after a short delay
        }
    }

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    function currentSlide(n) {
        showSlides(slideIndex = n);
    }

    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("slide");
        if (n >= slides.length) { slideIndex = 0 }
        if (n < 0) { slideIndex = slides.length - 1 }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }

        slides[slideIndex].style.display = "block";
    }
});